<template>
  <div>
    <b-alert :show="showAlert" variant="danger">
      <span>{{ alertMsg }}</span>
    </b-alert>
    <div class="row">
      <div class="col-md-9">
        <div class="col-md-9 px-2">
        
      </div>
      <div class="card card-custom gutter-b">
          <div class="form-group row d-flex align-items-center flex-col px-6 pt-4" style="gap: 8px;">
            <label class="col-12 col-md-4 font-weight-bold">{{ $t('LOGISTIC_ANALYSIS.DEVICE_FILTERS') }}:</label>
            <div class="col-12 col-md-8 d-flex flex-wrap" style="gap: 8px;">
              <div v-for="(filter, index) in deviceFilters" :key="filter.name" class="custom-checkbox-container">
                <input
                  type="checkbox"
                  :id="filter.name"
                  :checked="filter.value"
                  @change="onFilterChange(index)"
                  class="custom-checkbox"
                />
                <label :for="filter.name" class="custom-label">{{ filter.name }}</label>
              </div>
            </div>
          </div>
          <div v-if="!isLocationsLoaded" class="row">
            <div class="col-12 pt-4 pb-6 text-center px-6 pl-10">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
          </div>
          <div v-if="isLocationsLoaded && deviceFilters.every(filter => !filter.value)" class="row">
            <div class="col-12 text-center pt-4 pb-6 pl-10">
              <h3 class="card-title" style="font-size: 14px; font-weight: 400;">{{ $t('LOGISTIC_ANALYSIS.APPLY_FILTER') }}</h3>
            </div>
          </div>
          <div v-if="isLocationsLoaded && locations.length === 0" class="row">
            <div class="col-12 text-center pl-10">
              <h3 class="card-title" style="font-size: 14px; font-weight: 400;">{{ $t('LOGISTIC_ANALYSIS.NO_DATA') }}</h3>
            </div>
          </div>
           <div v-if="isDevicesLoaded && selectedDevices.length === 0" class="row">
            <div class="col-12 text-center pl-10">
              <h3 class="card-title" style="font-size: 14px; font-weight: 400;">{{ $t('LOGISTIC_ANALYSIS.NO_DATA') }}</h3>
            </div>
          </div>
          <div v-else-if="isLocationsLoaded && deviceFilters.some(filter => filter.value)" class="card-body px-2" style="margin-top: -24px;">
            <div class="card card-custom gutter-b">
          <div class="card-body">
            <DualListBox
              :source="locations"
              :destination="selectedLocations"
              label="title"
              @onChangeList="onChangeList"
            />
            <div class="pt-4 px-4">
              <button
                v-if="!isGenerated"
                class="btn btn-primary btn-block font-weight-bold"
                @click="generate"
              >
                Generate
              </button>
              <button
                v-else
                class="btn btn-primary btn-block font-weight-bold"
                @click="resetAll"
              >
                Reset All
              </button>
            </div>
          </div>
        </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <DatePicker
              ref="picker"
              v-model="range"
              mode="dateTime"
              :attributes="attributes"
              :masks="masks"
              locale="hr"
              is-range
              is24hr
              is-expanded
              :columns="$screens({ default: 1, lg: 1 })"
              :max-date="new Date()"
            >
            </DatePicker>
            <button
              @click="last7Days"
              class="btn btn-light-primary btn-block font-weight-bold mt-2"
            >
              Last 7 days
            </button>
            <button
              @click="last30Days"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last 30 days
            </button>
            <button
              @click="thisMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              This month
            </button>
            <button
              @click="lastMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last month
            </button>
            <button
              @click="reset"
              class="btn btn-light-danger btn-block font-weight-bold mt-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      <div v-if="isGenerated" class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Locations Traffic</h3>
          </div>
          <div v-if="!isLoaded" class="my-5">
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
            </div>
          </div>
          <div v-else-if="isLoaded && generatedResults.length > 0 && generatedDeviceResults.length > 0 && selectedDevices.length > 0" class="p-0">
            <LocationsTraffic
              :traffic="generatedDeviceResults"
              :generatedResults="generatedResults"
              :selected="selectedDevices"
              :range="range"
              :deviceType="this.deviceFilters.find((item) => item.value).name"
            />
          </div>
        </div>
      </div>
      <div v-else-if="isLoaded && selectedDevices.length === 0" class="col-md-12" style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
        <div class="col-12 pt-4 pb-6 text-center px-6 py-10 pl-10 card">
          <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
            <p class="font-weight-bold" style="font-size: 24px;">No devices.</p>
            <p style="font-size: 16px;">There are currently no devices.</p>
          </div>
        </div>
      </div>
      <div v-if="isGenerated" class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Transactions Traffic</h3>
          </div>
          <div v-if="!isLoaded" class="my-5">
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
            </div>
          </div>
          <div v-else-if="isLoaded && generatedResults.length > 0 && selectedDevices.length > 0" class="card-body p-0">
            <TransactionsTraffic
              :traffic="generatedResults"
              :selected="selectedDevices"
              :range="range"
              :deviceType="this.deviceFilters.find((item) => item.value).name"
            />
          </div>
        </div>
    </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import format from 'date-fns/format'
import add from 'date-fns/add'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import addMonths from 'date-fns/addMonths'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import DualListBox from '@/view/content/components/dual-list-box/DualListBox'
import LocationsTraffic from '@/view/pages/logistic-report/_components/LocationsTraffic'
import TransactionsTraffic from '@/view/pages/logistic-report/_components/TransactionsTraffic'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { dateFilter } from 'vue-date-fns'
import ApiService from '@/core/services/api.service'
import addDays from 'date-fns/addDays'

export default {
  name: 'LocationTokenReport',
  filters: {
    date: dateFilter,
  },
  components: {
    DatePicker,
    DualListBox,
    LocationsTraffic,
    TransactionsTraffic
  },
  data() {
    return {
      deviceFilters: [
        { name: 'Saldo Reducers', value: false }, 
        { name: 'Monsters', value: false }, 
        { name: 'Prize Devices', value: false }, 
      ],
      locations: [],
      selectedLocations: [],
      locationsDevices: [],
      generatedResults: [],
      selectedDevices: [],
      isDevicesLoaded: false,
      totalItems: null,
      range: {
        start: add(new Date(), { days: -1 }).setHours(24, 0, 0),
        end: new Date().setHours(23, 59, 59),
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      showAlert: false,
      alertMsg: null,
      base: 0,
      tax: 0,
      total: 0,
      isGenerated: false,
      showSpinner: false,
      isLocationsLoaded: false,
      areFilteredDevicesLoaded: false,
      isLoaded: false
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentActiveModuleComponents',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Location reports', route: '/token-report/location' },
    ])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      ApiService.get('locations', '?pagination=false').then(({ data }) => {
        this.totalItems = data['hydra:member'].length
        this.locations = [...data['hydra:member']]

        this.isLocationsLoaded = true
      })
    }
    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER'
    ) {
      ApiService.get(
        'locations',
        `?company=${this.currentUserCompany}&pagination=false`
      ).then(({ data }) => {
        this.totalItems = data['hydra:member'].length
        this.locations = [...data['hydra:member']]

        this.isLocationsLoaded = true
      })
    }
  },
  methods: {
    onFilterChange(selectedIndex) {
      this.deviceFilters.forEach((filter, index) => {
        filter.value = index === selectedIndex ? !filter.value : false;
      });

      this.locationsDevices = [];
      this.selectedLocations = []

      this.areFilteredDevicesLoaded = true;
    },
    async generate() {
      // Validate input
      if (
          this.selectedLocations.length === 0 ||
          this.range.start === null ||
          this.range.end === null
      ) {
          this.alertMsg = 'Please select location/s & pick range';
          this.showAlert = true;
          return;
      }

      this.showSpinner = true;
      this.generatedResults = [];
      this.generatedDeviceResults = [];
      let num = 0;

      const after = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss");
      const before = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss");

      this.selectedLocations.forEach((location) => {
          ApiService.get(
              'devices',
              `?company=${this.currentUserCompany.replace('/api/v1/companies/', '')}&pagination=false&new=1&select=flags,timestamps,related_ids`
          ).then((response) => {
              const filterName = this.deviceFilters.find((item) => item.value).name;

              response.data['hydra:member'].forEach((device) => {
                  if (
                      (filterName === 'Saldo Reducers' && device.isSaldoReducer && device.location.title === location.title) ||
                      (filterName === 'Monsters' && device.isMonsterDevice && device.location.title === location.title) ||
                      (filterName === 'Prize Devices' && device.isPrizeDevice && device.location.title === location.title)
                  ) {
                      this.selectedDevices.push(device);
                  }
              });

              // Iterate over selected devices
              this.selectedDevices.forEach((device) => {
                  ApiService.get(
                      'devices',
                      `?company=${this.currentUserCompany.replace('/api/v1/companies/', '')}&pagination=false&new=1&select=flags,timestamps,related_ids`
                  ).then(({ data }) => {
                      if (data) {
                          data['hydra:member'].forEach((item) => {
                              if (item.id === device.id) {
                                  this.generatedDeviceResults.push(item);

                                  if (filterName === 'Saldo Reducers') {
                                      this.processSaldoReducers(device, after, before);
                                  } else if (filterName === 'Monsters' || filterName === 'Prize Devices') {
                                      this.processOtherDevices(device, after, before);
                                  }
                              }
                          });
                      }
                  });
                  
                  num++;

                  // Check if all devices have been processed
                  if (num === this.selectedDevices.length) {
                      setTimeout(() => {
                          this.isGenerated = true;
                          this.showSpinner = false;
                      }, 1000);
                  }
              });
          })
          .finally(() => {
              this.isLoaded = true;
          });
      });
  },
  async processSaldoReducers(device, after, before) {
      ApiService.get(
          'messages',
          `?imei=${device.imei}&pagination=false&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc`
      ).then(({ data }) => {
          const messages = data['hydra:member'];

          ApiService.get('device_configurations', `?imei=${device.imei}`)
              .then(({ data }) => {
                  const deviceConfig = data['hydra:member'][0];
                  const cctalkConfig = deviceConfig?.configuration?.cctalk;

                  if (cctalkConfig?.status) {
                      const outputValues = cctalkConfig.output_values;

                      messages
                          .filter((message) => message.data.some((item) => item.type === 'pay_cctalk'))
                          .forEach((message) => {
                              const payloadItem = message.data.find((item) => item.type === 'pay_cctalk');
                              const amount = outputValues[payloadItem.payload];

                              this.generatedResults.push({
                                  deviceName: device.name,
                                  imei: device.imei,
                                  amount: amount,
                                  paymentType: 'pay_cctalk',
                                  createdAt: format(new Date(message.createdAt), 'dd.MM.yyyy @ HH:mm:ss'),
                                  tags: [],
                              });
                          });
                  } else {
                      console.log(`CCTalk configuration is not available.`);
                  }
              })
              .catch((error) => {
                  console.log(`Error fetching device configuration: ${error}`);
              });
      }).catch((error) => {
          console.log(`Error fetching messages: ${error}`);
      });
  },
  async processOtherDevices(device, after, before) {
      ApiService.get(
          'transactions',
          `?device=${device.id}&company=${this.currentUserCompany}&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false`
      ).then(({ data }) => {
          data['hydra:member'].forEach(({ id, imei, deviceMeta, amount, paymentType, createdAt, locationMeta }) => {
              if(amount >= 0 && paymentType !== 'token_drop') {
                this.generatedResults.push({
                  id: id,
                  deviceId: device.id,
                  isSaldoReducer: device.isSaldoReducer,
                  isPrizeDevice: device.isPrizeDevice,
                  isMonsterDevice: device.isMonsterDevice,
                  deviceSaldo: device.saldo,
                  tokenMinimum: device.tokenMinimum,
                  saldoMinimum: device.saldoMinimum,
                  imei: imei,
                  token: device.token,
                  deviceName: deviceMeta.name,
                  location: locationMeta?.title || '',
                  lessor: locationMeta?.lessor || '',
                  address: locationMeta?.address || '',
                  tags: locationMeta?.tags || [],
                  amount: amount,
                  paymentType: paymentType,
                  createdAt: format(new Date(createdAt), 'dd.MM.yyyy @ HH:mm:ss'),
                });
              }
          });
      });
  },
    last7Days() {
      this.$refs.picker.updateValue({ start: null, end: null })
      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -7 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    last30Days() {
      this.$refs.picker.updateValue({ start: null, end: null })
      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -30 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    thisMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })
      const today = new Date()

      this.$refs.picker.updateValue({
        start: startOfMonth(today),
        end: today,
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 2,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    lastMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: startOfMonth(addMonths(new Date(), -1)),
        end: endOfMonth(addMonths(new Date(), -1)),
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 1,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    reset() {
      this.$refs.picker.updateValue({ start: null, end: null })
    },
    resetAll() {
      this.generatedResults = []
      this.totalItems = null
      this.range.start = null
      this.range.end = null
      this.base = 0
      this.tax = 0
      this.total = 0
      this.isGenerated = false
      this.showSpinner = false
      this.isLocationsLoaded = false
      this.isDevicesLoaded = false
    },
    onChangeList: function ({ source, destination }) {
      this.locations = source
      this.selectedLocations = destination
    },
    setStartEndDate() {
      let a = addDays(this.range.start, -1)
      a.setHours(24, 0, 0)
      this.range.start = a

      let b = addDays(this.range.end, 0)
      b.setHours(23, 59, 59)
      this.range.end = b

      this.$refs.picker.updateValue({
        start: this.range.start,
        end: this.range.end,
      })
    },
  },
}
</script>

<style lang="scss">
.card-title {
  display: flex;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}
/* Container for the custom checkbox and label */
.custom-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

/* Hide the default checkbox appearance */
.custom-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #007bff;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease-in-out;
}

/* Add a checkmark when checked */
.custom-checkbox:checked::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 5px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Background color on check */
.custom-checkbox:checked {
  background-color: #007bff;
  border-color: #007bff;
}

/* Add a hover effect */
.custom-checkbox:hover {
  border-color: #0056b3;
}

/* Label styling */
.custom-label {
  font-size: 18px;
  cursor: pointer;
  color: #333;
  margin-top: 8px;
}

</style>